.ticket-block {
    height: 60px;
    margin-bottom: 1rem;
}

.log-card-flag {
    background-color: red;
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    margin-right: 4px;
}

.btn-ticket-qty {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 3em !important;
    font-weight: bold;
}

.history-wrapper {
    height: 60vh;
    overflow-y: scroll;
    border: 1px solid #c9c9c9;
    padding: 6px;
}
