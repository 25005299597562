.lowlight {
  opacity: 0.4;
}

.btn-select {
  color: black !important;
}

.btn-select:hover {
  color: white !important;
}

.row select {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.nav-link {
  font-weight: bold;
  color: rgb(160, 160, 160) !important;
  background-color: #e6e6e6 !important;
  border-top: none !important;
  border-bottom: none !important;
}

.nav-link.active {
  color: #fff !important;
  background-color: #1d2434 !important;
}
